import React, { useState, useCallback } from 'react'
import { node } from 'prop-types'

import Client from 'shopify-buy'

const client = Client.buildClient({
  domain: `${process.env.GATSBY_SHOP_NAME}.myshopify.com`,
  storefrontAccessToken: process.env.GATSBY_ACCESS_TOKEN,
})

const localStorageCheckoutId = 'oldtreeshoopcheckout'

const defaultState = {
  cart: {},
}

const CartContext = React.createContext(defaultState)
export default CartContext

export function CartContextProvider({ children }) {
  const windowIsAvailable = typeof window !== 'undefined' && window.document

  const [checkout, setCheckout] = useState(
    JSON.parse(
      windowIsAvailable ?
        localStorage.getItem(localStorageCheckoutId)
        : null,
    ),
  )

  const [fetchingCart, setFetchingCart] = useState(false)

  React.useEffect(() => {
    // Init checkout client side
    if (windowIsAvailable) {
      setCheckout(localStorage.getItem(localStorageCheckoutId))
    }
  }, [windowIsAvailable])

  const [successfulOrder, setSuccessfulOrder] = useState(null)
  const checkoutId = checkout?.id

  const getCheckout = useCallback(async () => {
    if (checkoutId && windowIsAvailable) {
      setFetchingCart(true)
      const fetchedCheckout = await client.checkout.fetch(checkoutId)
      setFetchingCart(false)
      if (fetchedCheckout?.completedAt) {
        localStorage.removeItem(localStorageCheckoutId)
        setCheckout(null)
        setSuccessfulOrder(fetchedCheckout)
      } else {
        setCheckout(fetchedCheckout)
        localStorage.setItem(
          localStorageCheckoutId,
          JSON.stringify(fetchedCheckout),
        )
      }
    }
  }, [checkoutId, windowIsAvailable])

  React.useEffect(() => {
    getCheckout()
  }, [getCheckout])

  const verifyCartIsPaied = () => {
    if (windowIsAvailable && !document.hidden) {
      getCheckout()
    }
  }

  const goToBuy = () => {
    if (windowIsAvailable && checkout?.webUrl) {
      document.addEventListener('visibilitychange', verifyCartIsPaied)
      window.open(checkout.webUrl)
    }
  }

  const getProductByShopifyId = async productShopifyId => {
    const product = await client.product.fetch(productShopifyId)
    return product
  }

  const updateLineItem = async ({ variantId, quantity }) => {
    // if no checkout id, create a new checkout
    let newCheckout = checkout || (await client.checkout.create())

    // check to see if this variantId exists in storedCheckout
    const lineItemVariant = newCheckout.lineItems?.find(
      lineItem => lineItem.variant.id === variantId
    )

    if (lineItemVariant) {
      const newQuantity = lineItemVariant.quantity + quantity

      if (newQuantity) {
        newCheckout = await client.checkout.updateLineItems(newCheckout.id, [
          {
            id: lineItemVariant.id,
            quantity: newQuantity,
          },
        ])
      } else {
        newCheckout = await client.checkout.removeLineItems(newCheckout.id, [
          lineItemVariant.id,
        ])
      }
    } else {
      newCheckout = await client.checkout.addLineItems(newCheckout.id, [
        {
          variantId,
          quantity,
        },
      ])
    }

    setCheckout(newCheckout)
    setSuccessfulOrder(null)
    if (windowIsAvailable) {
      localStorage.setItem(localStorageCheckoutId, JSON.stringify(newCheckout))
    }
  }

  const removeLineItem = async lineItemId => {
    const newCheckout = await client.checkout.removeLineItems(checkout.id, [
      lineItemId,
    ])

    setCheckout(newCheckout)
  }

  const dismissSuccessfulOrder = () => {
    setSuccessfulOrder(null)
  }

  return (
    <CartContext.Provider
      value={{
        checkout,
        updateLineItem,
        removeLineItem,
        getProductByShopifyId,
        successfulOrder,
        dismissSuccessfulOrder,
        fetchingCart,
        goToBuy,
      }}
    >
      {children}
    </CartContext.Provider>
  )
}

CartContextProvider.propTypes = {
  children: node.isRequired,
}
