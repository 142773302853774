import React from 'react'
import { node } from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'

const defaultState = {
  products: [],
}

const query = graphql`
  {
    allShopifyProduct {
      edges {
        node {
          ...ShopPageProduct
        }
      }
    }
    allShopifyCollection {
      edges {
        node {
          id
          title
          products {
            shopifyId
          }
        }
      }
    }
  }
`

const ProductContext = React.createContext(defaultState)
export default ProductContext

export function ProductContextProvider({ children }) {
  const { allShopifyProduct, allShopifyCollection } = useStaticQuery(query)

  const products = (
    allShopifyProduct?.edges?.map(({ node }) => node) || [])
    .filter(Boolean)

  const collections = (
    allShopifyCollection?.edges?.map(({ node }) => node) || [])
    .filter(Boolean)

  return (
    <ProductContext.Provider
      value={{
        products,
        collections,
      }}
    >
      {children}
    </ProductContext.Provider>
  )
}

ProductContextProvider.propTypes = {
  children: node.isRequired,
}
