export const mobile = 640
export const tablet = 1000
export const laptop = 1200

export default {
  size: {
    fontSize: {
      xs: '12px',
      s: '14px',
      m: '16px',
      l: '18px',
      xl: '25px',
      xxl: '35px',
    },
    icon: '30px',
  },
  layout: {
    header_height: '60px',
    main_max_width: '1600px',
  },
  breakPoint: {
    mobile: `${mobile}px`,
    tablet: `${tablet}px`,
    laptop: `${laptop}px`,
  },
  animation: {
    func_animation: 'cubic-bezier(0.4, 0.0, 0.2, 1)',
    time_animation: '300ms',
    composed: '300ms cubic-bezier(0.4, 0.0, 0.2, 1)',
  },
  color: {
    text: 'rgba(255, 255, 255, 0.8)',
    text_negative: 'rgba(0, 0, 0, 0.9)',
    primary: {
      dark: '#212121',
      main: '#303030',
      light: '#424242',
    },
    secondary: {
      dark: '#b28035',
      main: '#ffe082',
      light: '#ffe69b',
    },
    red: 'rgba(255, 0, 0, 0.9)',
  },
}
