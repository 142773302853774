import React, { useState, useEffect } from 'react'
import { node } from 'prop-types'
import { ThemeProvider as StyledProvider } from 'styled-components'

import theme from '../Theme'
import { mobile, laptop } from '../Theme/constants'

import { GlobalStyle } from '../Theme/globalStyles'

const initDeviceWidth = windowIsAvailable => {
  if (windowIsAvailable) {
    return window.innerWidth
  }

  return laptop
}

const ThemeProvider = ({ children }) => {
  const windowIsAvailable = typeof window !== 'undefined' && window.document

  const [width, setWidth] = useState(initDeviceWidth(windowIsAvailable))

  useEffect(() => {
    const handleWindowResize = () => {
      if (windowIsAvailable) {
        setWidth(window.innerWidth)
      }
    }

    if (windowIsAvailable) {
      window.addEventListener('resize', handleWindowResize)
      return () => window.removeEventListener('resize', handleWindowResize)
    }

    return null
  }, [windowIsAvailable])

  const device = {
    isMobile: width <= mobile,
    isTablet: width > mobile && width <= laptop,
    isLaptop: width > laptop,
  }

  return (
    <StyledProvider theme={{ ...theme, device }}>
      <GlobalStyle />
      { children }
    </StyledProvider>
  )
}

ThemeProvider.propTypes = {
  children: node.isRequired,
}

export default ThemeProvider
