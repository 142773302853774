import { createGlobalStyle } from 'styled-components'
import reset from 'styled-reset'

export const GlobalStyle = createGlobalStyle`
  ${reset}
  html {
    box-sizing: border-box;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }
  
  body {
    font-family: 'Lato', sans-serif;
    line-height: 1.2;
    background-color: ${({ theme }) => theme.color.primary.dark};
    color: ${({ theme }) => theme.color.text};
    font-size: ${({ theme: { size } }) => size.fontSize.m};
  }
  
  .old-tree-icons {
    font-size: ${({ theme: { size } }) => size.icon};
    color: ${({ theme: { color } }) => color.text};
  }
`
