import React from 'react'
import { ProductContextProvider } from './src/Providers/ProductProvider'
import { CartContextProvider } from './src/Providers/CartProvider'
import ThemeProvider from './src/Providers/ThemeProvider'
import './src/globalstyle.css'

export const wrapRootElement = ({ element }) => (
  <ProductContextProvider>
    <CartContextProvider>
      <ThemeProvider>
        {element}
      </ThemeProvider>
    </CartContextProvider>
  </ProductContextProvider>
)
